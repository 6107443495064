// extracted by mini-css-extract-plugin
export var fadeIn = "d_m";
export var featuresSection = "d_r";
export var featuresSectionImage = "d_s";
export var headSection = "d_k";
export var headSectionImage = "d_n";
export var headSectionLogo = "d_l";
export var icons = "d_v";
export var relatedProductsSection = "d_t";
export var textSection = "d_p";
export var textSectionVideo = "d_q";